import { fetchApi } from '@/utils/axios';
import URL from '@/api/urlConfig';
import http from "@/utils/axios"

// 获取所有项目
export const getProjects = () => fetchApi(URL.BINDING_PROJECT_LIST);

// 查询优惠券
export const findCouponInfo = (couponId) => fetchApi(`${URL.GET_COUPON_INFO_BY_ID}/${couponId}`);

// 获取所有人群
export const findAllCrowd = () => fetchApi(URL.GET_ALL_CROWD);

// 添加发优惠券活动
export const addGrantCouponActivity = (data) => fetchApi(URL.ADD_GRANT_COUPON_ACTIVITY, data, 'post', 'data');

// 获取优惠券活动列表
export const getCouponActivityList = (data) => fetchApi(URL.GET_COUPON_ACTIVITY_LIST, data, 'post', 'data');

// 获取优惠券活动详情
export const getCouponActivityDetail = (id) => fetchApi(`${URL.GET_COUPON_ACTIVITY_DETAIL}/${id}`);

// 编辑优惠券活动
export const editGrantCouponActivity = (data) => fetchApi(URL.EDIT_GRANT_COUPON_ACTIVITY, data, 'put', 'data');

// 优惠券活动启禁用
export const couponActivityDisabled = (id, disabled) => fetchApi(`${URL.COUPON_ACTIVITY_DISABLED}/${id}/${disabled}`);

// 下载优惠券活动商户模版
export const downloadCouponActivityMerchantTemplate = () => {
  return http({
    url: URL.DOWNLOAD_COUPON_ACTIVITY_MERCHANT_TEMPLATE,
    responseType: 'blob',
    method: 'post',
    params: {},
  });
};
