<template>
  <div class="addPassReceiveCoupon">
    <a-form-model ref="ruleForm" layout="horizontal" :model="form" :label-col="labelCol" :wrapper-col="wrapperCol"
      :rules="rules">
      <a-row>
        <a-col :span="span">
          <a-form-model-item label="活动名称" prop="activityName">
            <a-input placeholder="请输入活动名称" v-model.trim="form.activityName" allowClear :disabled="isDisabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="活动有效期" prop="activityIndate">
            <a-range-picker :disabled="isDisabled" format="YYYY-MM-DD HH:mm" valueFormat="YYYY-MM-DD HH:mm:ss"
              :placeholder="['开始时间', '结束时间']" v-model="form.activityIndate"
              :getCalendarContainer="(triggerNode) => triggerNode.parentNode" :disabled-date="disabledActivityIndateDate"
              :show-time="{ format: 'HH:mm' }" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="所属项目" prop="projectIds">
            <a-select :disabled="isDisabled" placeholder="请选择所属项目" mode="multiple" v-model="form.projectIds"
              :maxTagCount="isDisabled ? 100 : 2" allowClear :filter-option="filterOption"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="item in projectList" :key="item.id">
                {{ item.project_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="关联优惠券ID" prop="couponId">
            <a-input placeholder="请输入优惠券ID" v-model.trim="form.couponId" @change="inputCouponId" :disabled="isDisabled || $route.query.type ==='edit'" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="每人限领张数" prop="restrictTotal">
            <a-input placeholder="根据优惠券ID设置展示每人限领张数" v-model.trim="form.restrictTotal" :disabled="true" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="通行记录日期" prop="passRecordDate">
            <a-range-picker :disabled="isDisabled" valueFormat="YYYY-MM-DD" :placeholder="['开始日期', '结束日期']"
              v-model="form.passRecordDate" :getCalendarContainer="(triggerNode) => triggerNode.parentNode"
              :disabled-date="disabledPassRecordDate" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="通行记录时间段" required>
            <div style="display: flex;">
              <a-form-model-item prop="passStartTime">
                <a-time-picker style="width: 200px" v-model="form.passStartTime" format="HH:mm:ss" valueFormat="HH:mm:ss" placeholder="开始时间"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode" :disabled="isDisabled"
                />
              </a-form-model-item>
              <div style="width: 50px; text-align: center;">
                -
              </div>
              <a-form-model-item prop="passEndTime">
                <a-time-picker style="width: 200px" v-model="form.passEndTime" format="HH:mm:ss" valueFormat="HH:mm:ss" placeholder="结束时间"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode" :disabled="isDisabled"
                />
              </a-form-model-item>
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="用户人群" prop="crowdIds">
            <a-select :disabled="isDisabled" placeholder="请选择用户人群" mode="multiple" v-model="form.crowdIds"
              :maxTagCount="isDisabled ? 100 : 2" allowClear :filter-option="filterOption"
              :getPopupContainer="(triggerNode) => triggerNode.parentNode">
              <a-select-option v-for="item in peopleList" :key="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="适用商户">
            <a-upload :disabled="isDisabled" :action="IMG_API + '/oss/files'" :file-list="form.fileJson"
              @change="handleChange" accept=".xlsx">
              <a-button type="primary" :disabled="isDisabled || form.fileJson.length ? true : false"> <a-icon
                  type="upload" />
                点击上传
              </a-button>
              <span style="margin-left: 10px;"><a-button type="link" :disabled="isDisabled"
                  @click.stop="download">下载模版</a-button></span>
            </a-upload>
            <div>
              注：支持扩展名：xlsx
            </div>
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="消息备注文案" prop="pushContent">
            <a-input placeholder="建议输入不超过15个字" v-model.trim="form.pushContent" :disabled="isDisabled" :maxLength="15"
              allowClear />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item label="活动状态" prop="status">
            <a-radio-group :options="activityStatusOptions" v-model="form.status" :disabled="isDisabled" />
          </a-form-model-item>
        </a-col>
        <a-col :span="span">
          <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button v-if="!isDisabled" type="primary" @click="save">
              保存
            </a-button>
            <a-button style="margin-left: 10px;" @click="goBack">
              {{ `${isDisabled ? '返回' : '取消'}` }}
            </a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>

<script>
import * as api from '@/api/passReceiveCouponManagement';
import { SUCCESS_CODE } from '@/config';
import _ from 'lodash';
import { IMG_API } from "@/config";
import moment from "moment";
export default {
  name: 'addPassReceiveCoupon',
  computed: {
    isDisabled() {
      return this.$route.query.type === 'view' ? true : false;
    },
    disabledStartHoursList() {
      let hours = [];
      if (this.form.passEndTime) {
        const endHour = parseInt(this.form.passEndTime.split(':')[0])
        const endMinute = parseInt(this.form.passEndTime.split(':')[1]);
        const hour = endHour;
        for (let i = 0; i < 24; i++) {
          if (endMinute === 0) {
            if (i > hour - 1) {
              hours.push(i);
            }
          } else {
            if (i > hour) {
              hours.push(i);
            }
          }
        }
      }
      return hours
    },
    disabledStartMinuteList() {
      if (this.form.endTime) {

      } else {
        return []
      }
    },
    disabledEndHoursList() {
      let hours = [];
      if (this.form.passStartTime) {
        const startHour = parseInt(this.form.passStartTime.split(':')[0])
        const startMinute = parseInt(this.form.passStartTime.split(':')[1]);
        const hour = startHour;
        if (startMinute === 59) {
          for (let i = 0; i < hour + 1; i++) {
            hours.push(i);
          }
        } else {
          for (let i = 0; i < hour; i++) {
            hours.push(i);
          }
        }

      }
      return hours
    },
    disabledEndMinuteList() {
      if (this.form.endTime) {

      } else {
        return []
      }
    },
  },
  data() {
    return {
      IMG_API,
      labelCol: { span: 10 },
      wrapperCol: { span: 14 },
      span: 13,
      projectList: [],
      peopleList: [],
      fileList: [],
      activityStatusOptions: [
        { label: '启用', value: 1 },
        { label: '禁用', value: 0 },
      ],

      form: {
        activityId: '', // 活动id
        activityName: '', // 活动名称
        activityIndate: [], // 活动有效期
        startTime: '', // 开始时间
        endTime: '', // 结束时间
        projectIds: [], // 项目ID集合
        couponId: '', // 关联优惠券ID
        restrictTotal: '', // 每人限领张数
        passRecordDate: [], // 通行记录日期
        passStartDate: '', // 通行开始日期
        passEndDate: '', // 通行结束日期
        passStartTime: '', // 通行开始时间
        passEndTime: '', // 通行结束时间
        crowdIds: [], // 人群ID集合
        uploadMerchantFileUrl: '', // 上传商户文件URL
        pushContent: '', // 推送消息文案
        status: 1, // 状态（0：禁用 1：启用）
        fileJson: []
      },
      rules: {
        activityName: [
          { required: true, message: '请输入活动名称', trigger: ['blur', 'change'] },
        ],
        activityIndate: [
          { required: true, message: '请设置活动有效期', trigger: ['blur', 'change'] },
        ],
        projectIds: [
          { required: true, message: '请选择所属项目', trigger: ['blur', 'change'] },
        ],
        couponId: [
          { required: true, message: '请输入优惠券ID', trigger: ['blur', 'change'] },
        ],
        restrictTotal: [
          { required: true, message: '根据优惠券ID设置展示每人限领张数', trigger: ['blur', 'change'] },
        ],
        passRecordDate: [
          { required: true, message: '请设置通行记录日期', trigger: ['blur', 'change'] },
        ],
        passStartTime: [
          { required: true, message: '请设置开始时间', trigger: ['blur', 'change'] },
        ],
        passEndTime: [
          { required: true, message: '请设置结束时间', trigger: ['blur', 'change'] },
        ],
        crowdIds: [
          { required: true, message: '请选择用户人群', trigger: ['blur', 'change'] },
        ],
        pushContent: [
          { required: true, message: '请输入消息备注文案', trigger: ['blur', 'change'] },
        ],
        status: [
          { required: true, message: '请设置活动状态', trigger: ['blur', 'change'] },
        ]
      },
    };
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    save() {
      console.log(this.form)
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.$route.query.type === 'add') {
            this.addGrantCouponActivity();
          } else {
            this.editGrantCouponActivity();
          }
        }
      })
    },
    goBack() {
      this.$router.back()
    },
    inputCouponId: _.debounce(function (e) {
      console.log(e.target.value);
      this.findCouponInfo(e.target.value)
    }, 500),

    handleChange(info) {
      console.log(info);
      let fileList = [...info.fileList];
      if (!fileList.length) {
        this.form.uploadMerchantFileUrl = '';
      }
      let file = info.file;
      if (file.status === 'done' && file.response) {
        this.form.uploadMerchantFileUrl = file.response.redirect_uri;
      }

      this.form.fileJson = fileList;
    },
    disabledPassRecordDate(current) {
      if (this.form.activityIndate.length) {
        return current < moment(this.form.activityIndate[0]).startOf('day') || current > moment(this.form.activityIndate[1]).endOf('day');
      }
    },
    disabledActivityIndateDate(current) {
      if (this.form.passRecordDate.length) {
        return current >= moment(this.form.passRecordDate[0]).endOf('day') && current <= moment(this.form.passRecordDate[1]);
      }
    },
    download() {
      this.downloadTemplate();
    },
    //获取不可选择的小时
    disabledStartHours() {
      return this.disabledStartHoursList;
    },
    disabledStarMinutes() {
      return this.disabledStartMinuteList;
    },
    //获取不可选择的小时
    disabledEndHours() {
      return this.disabledEndHoursList;
    },
    //获取不可选择的分钟
    disabledEndMinutes() {
      return this.disabledEndMinuteList;
    },

    /**网络请求 */
    // 获取项目列表
    async getProjects() {
      try {
        const res = await api.getProjects();
        if (res.code === SUCCESS_CODE) {
          this.projectList = res.data.items;
        }
      } catch (error) {

      }
    },
    // 查询优惠券信息
    async findCouponInfo(couponId) {
      try {
        const res = await api.findCouponInfo(couponId);
        console.log(res);
        if (res.code === SUCCESS_CODE) {
          this.form.restrictTotal = res.data.everyoneLimit;
        } else {
          this.form.couponId = '';
          this.$message.error(res.msg);
        }
      } catch (error) {
        this.$message.error('查询失败');
      }
    },
    // 获取所有人群
    async findAllCrowd() {
      try {
        const res = await api.findAllCrowd();
        if (res.code === SUCCESS_CODE) {
          this.peopleList = res.data
        }
      } catch (error) {

      }
    },
    // 添加发优惠券活动
    async addGrantCouponActivity() {
      try {
        const res = await api.addGrantCouponActivity(this.submitDataDispose());
        if (res.code === SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.$router.back();
        } else {
          this.$message.success(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 获取优惠券活动详情
    async getCouponActivityDetail() {
      try {
        const res = await api.getCouponActivityDetail(this.$route.query.id);
        if (res.code === SUCCESS_CODE) {
          this.detailDataDispose(res.data);
        }
      } catch (error) {

      }
    },
    // 编辑发优惠券活动
    async editGrantCouponActivity() {
      try {
        const res = await api.editGrantCouponActivity(this.submitDataDispose())
        if (res.code === SUCCESS_CODE) {
          this.$message.success(res.msg);
          this.$router.back();
        } else {
          this.$message.success(res.msg);
        }
      } catch (error) {

      }
    },
    // 下载模版
    async downloadTemplate() {
      try {
        const res = await api.downloadCouponActivityMerchantTemplate();
        const blob = new Blob([res.data]);
        const objectUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = objectUrl;
        a.setAttribute("download", "适用商户模版.xlsx");
        a.click();
      } catch (error) {

      }
    },
    /**数据处理 */
    // 提交数据处理
    submitDataDispose() {
      if (this.$route.query.type === 'add') {
        let { activityIndate, passRecordDate, activityId, ...submitForm } = this.form;
        submitForm = {
          ...submitForm,
          startTime: activityIndate[0],
          endTime: activityIndate[1],
          passStartDate: passRecordDate[0],
          passEndDate: passRecordDate[1],
          fileJson: submitForm.fileJson ? JSON.stringify(submitForm.fileJson) : null
        }
        return submitForm;
      } else {
        let { activityIndate, passRecordDate, ...submitForm } = this.form;
        submitForm = {
          ...submitForm,
          startTime: activityIndate[0],
          endTime: activityIndate[1],
          passStartDate: passRecordDate[0],
          passEndDate: passRecordDate[1],
          fileJson: submitForm.fileJson ? JSON.stringify(submitForm.fileJson) : null
        }
        return submitForm;
      }

    },
    // 详情数据处理
    detailDataDispose(data) {
      const { createTime, effectiveTime, projectIdList, projectNames, ...form } = data
      this.form = {
        ...form,
        activityIndate: [data.startTime, data.endTime],
        passRecordDate: [data.passStartDate, data.passEndDate],
        fileJson: form.fileJson ? JSON.parse(form.fileJson) : []
      }
      console.log(this.form);
    }
  },
  created() {
    this.getProjects();
    this.findAllCrowd();
    if (this.$route.query.type !== 'add') {
      this.getCouponActivityDetail();
    }
  },
};
</script>

<style scoped lang="scss"></style>
